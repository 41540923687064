// eslint-disable-next-line import/no-extraneous-dependencies
import { serialize } from 'object-to-formdata'
import _ from 'lodash'

export default {
    data() {
        return {
            formErrors: null,
            formData: null,
            isFormData: true,
            exceptFields: [],
        }
    },
    methods: {
        loadFormData() {
            return _.cloneDeep(this.form)
        },
        serializeForm($form) {
            let $serialize = $form
            if (this.isFormData) {
                $serialize = serialize($form, {
                    indices: true,
                })
            }
            return $serialize
        },
        onSubmit() {
            let route = `/${this.resource}`

            let $form = this.loadFormData()
            if (this.isEdit) {
                route = `/${this.resource}/${this.resourceId}`
                // eslint-disable-next-line no-unused-vars
                $form = {
                    ...$form,
                    _method: 'put',
                }
            }
            if (this.submitMethod === 'PUT') {
                $form = {
                    ...$form,
                    _method: 'put',
                }
            }

            const formData = this.serializeForm($form)
            this.$store.dispatch('app/isLoading', true)
            this.$refs.form.validate()
                .then(result => {
                    if (result) {
                        this.axios.post(route, formData)
                            .then(response => {
                                // this.$store.dispatch('app/isLoading', false)
                                this.$store.dispatch('app/isLoading', false)
                                let toRoute = this.toRedirectResource ? this.toRedirectResource : this.resource

                                const is_route_real_state = route === '/real-estate' || route.includes('/real-estate/')

                                if (is_route_real_state) {
                                    toRoute = this.$route.fullPath.split('/')[1]
                                }

                                this.afterSubmit(response.data.message, `${toRoute}`, {
                                    resourceId: this.toResourceId,
                                }, is_route_real_state)
                            })
                            .catch(error => {
                                // this.$store.dispatch('app/isLoading', false)
                                this.$store.dispatch('app/isLoading', false)
                                this.$bvToast.toast(error.response.data.message, {
                                    title: this.$t('Error'),
                                    variant: 'danger',
                                    solid: true,
                                    autoHideDelay: 5000,
                                    appendToast: true,
                                })
                                this.$refs.form.setErrors(error.response.data.errors)
                            })
                            .finally(() => {
                                // this.$store.dispatch('app/isLoading', false)
                                this.$store.dispatch('app/isLoading', false)
                            })
                    } else {
                        // this.$store.dispatch('app/isLoading', false)4
                        this.$store.dispatch('app/isLoading', false)
                        this.formErrors = this.$refs.form.errors
                        this.$bvToast.toast(this.$t('messages.please_fill_all_the_required_fields'), {
                            title: this.$t('Error'),
                            variant: 'danger',
                            solid: true,
                            autoHideDelay: 5000,
                            appendToast: true,
                        })
                    }
                })
                .finally(() => {
                    // this.$store.dispatch('app/isLoading', false)
                })
        },
    },
    computed: {
        isEdit() {
            return !!this.$route.params.resourceId
        },
        resource() {
            return this.$route.meta.resource
        },
        resourceId() {
            return this.$route.params.resourceId
        },
        toResourceId() {
            return this.resourceId
        },
        submitMethod() {
            return ''
        },
        toRedirectResource() {
            return null
        },
        languages() {
            return {
                en: 'English',
                ar: 'Arabic',
            }
        },
    },
}
