<template>
  <div ref='mapContainer' class='map-container'></div>
</template>

<script>


import axios from 'axios'
import { websiteMainUrl } from '@/services/url.service'

export default {
  props: ['modelValue', 'isMarkerDisabled'],

  name: 'BaseMap',
  data: () => ({
    location: {
      lng: 46.7179,
      lat: 24.6402,
      bearing: 0,
      pitch: 0,
      zoom: 14.08,
    },
    query: '',
    places: [],
    marker: null,
    address: null,
    country: null,
    region: null,
    locality: null,
    place: null,
    map_coordinate: null,
    disabled_marker: false,
  }),
  mounted() {
    this.loadMapboxScript().then(() => {
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN // Replace with your actual access token
      mapboxgl.setRTLTextPlugin(
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
        null,
        true, // Lazy load the plugin
      )

      const { lng, lat, zoom, bearing, pitch } = {
        lng: 46.7179,
        lat: 24.6402,
        bearing: 0,
        pitch: 0,
        zoom: 14.08,
      }

      const bounds = new mapboxgl.LngLatBounds(
        new mapboxgl.LngLat(34.4949, 16.8522), // Southwest coordinates of Saudi Arabia
        new mapboxgl.LngLat(55.6667, 32.1546),
      )


      const map = new mapboxgl.Map({
        container: this.$refs.mapContainer,
        style: 'mapbox://styles/mapbox/satellite-streets-v12',
        center: [lng, lat],
        marker: true,
        maxBounds: bounds,

        language: 'ar',
        bearing,
        pitch,
        zoom,
        attributionControl: false, // Remove attribution control if needed
        logoPosition: 'bottom-right', // Adjust logo position if needed
        preserveDrawingBuffer: true, // Preserve drawing buffer if needed
        antialias: true, // Enable antialiasing if needed
        localIdeographFontFamily: 'sans-serif', // Set font family for RTL
        renderWorldCopies: false, // Disable rendering multiple world copies if needed
        fadeDuration: 0, // Set fade duration to 0 if needed
      })

      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
      })

      geocoder.addTo(map)

      geocoder.on('result', (e) => {
        // Handle the selected result
        this.goToPlace(e.result)
      })


      map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
        }),
      )

      this.marker = new mapboxgl.Marker({
        draggable: false, // If you want the marker to be draggable
      })

      if (!this.disabled_marker) {
        // Listen for the click event on the map
        map.on('click', (e) => {
          const { lng, lat } = e.lngLat

          this.placeMarker({ lng, lat })
          this.fetchLocationDetails({ lng, lat })
        })
      }


      // if (this.map_coordinate) {
      //   let { lng, lat } = this.map_coordinate
      //
      //   this.placeMarker({ lng, lat })
      // }
      const updateLocation = () =>
        this.$emit('update:modelValue', this.getLocation())

      this.map = map
    })

  },

  unmounted() {
    this.map.remove()
    this.map = null
  },


  watch: {
    isMarkerDisabled: {

      handler(val) {
        this.disabled_marker = val
      },
      immediate: true,

    },
    modelValue: {

      handler(newVal) {

        if (newVal.length > 0) {
          this.map_coordinate = newVal

          setTimeout(() => {
            this.goToPlaceExist(newVal)

          }, 1500)
        }
      },
      immediate: true,

    },
  },

  methods: {
    loadMapboxScript() {
      const scriptUrls = [
        'https://api.mapbox.com/mapbox-gl-js/v3.0.1/mapbox-gl.js',
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v5.0.0/mapbox-gl-geocoder.min.js',
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-language/v0.10.1/mapbox-gl-language.js',
        // 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
      ]

      const styleUrls = [
        'https://api.mapbox.com/mapbox-gl-js/v3.0.1/mapbox-gl.css',
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v5.0.0/mapbox-gl-geocoder.css',
      ]

      const loadScript = (url) => {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script')
          script.src = url
          script.onload = resolve
          script.onerror = reject
          document.head.appendChild(script)
        })
      }

      const loadStylesheet = (url) => {
        const style = document.createElement('link')
        style.href = url
        style.rel = 'stylesheet'
        document.head.appendChild(style)
      }

      const scriptPromises = scriptUrls.map(loadScript)
      const stylePromises = styleUrls.map(loadStylesheet)

      return Promise.all([...scriptPromises, ...stylePromises])
    },
    getLocation() {
      return {
        ...this.map.getCenter(),
        bearing: this.map.getBearing(),
        pitch: this.map.getPitch(),
        zoom: this.map.getZoom(),
      }
    },
    placeMarker(lngLat) {
      // Set the marker's location


      this.marker.setLngLat(lngLat)

      // Add the marker to the map
      this.marker.addTo(this.map)
    },

    // searchPlaces() {
    //   const country = 'SA'
    //   const city = 'Riyadh'
    //   const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${city},${this.query}.json?access_token=${mapboxgl.accessToken}&country=${country}&language=ar,en`
    //   axios.get(url)
    //     .then((response) => {
    //       this.places = response.data.features
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching places:', error)
    //     })
    // },

    goToPlace(place) {
      this.map.flyTo({
        center: place.center,
        zoom: 14,
        bearing: 0,
        pitch: 0,
      })

      const lngLat = { lng: place.center[0], lat: place.center[1] }

      this.placeMarker(lngLat)
      this.fetchLocationDetails(lngLat)

      // Clear the search results
      this.query = ''
      this.places = []
    },

    goToPlaceExist(place) {
      this.map.flyTo({
        center: place,
        bearing: 0,
        pitch: 0,
      })

      const lngLat = { lng: place[0], lat: place[1] }

      this.placeMarker(lngLat)
      this.fetchLocationDetails(lngLat)

      // Clear the search results
      this.query = ''
      this.places = []
    },


    handleMapClick(lngLat) {
      this.fetchLocationDetails(lngLat)
    },

    fetchLocationDetails(lngLat) {

      // this.$emit('mapClicked', true)

      this.$emit('childDataUpdated', lngLat)

      // this.axios.post(`${websiteMainUrl()}/location-info`, lngLat)
      //   .then(res => {
      //     const data = res.data.data
      //
      //     this.$emit('childDataUpdated', data)
      //
      //   }).catch((error) => {
      //   this.$emit('mapClicked', false)
      //   this.$emit('childDataUpdated', {
      //     'country_id': null,
      //     'region_id': null,
      //     'city_id': null,
      //     'district_id': null,
      //     'address_name': null,
      //     'lng': null,
      //     'lat': null,
      //   })
      //   console.error('Error fetching location details:', error)
      // })
    },

  },
}
</script>

<style>
.map-container {
  flex: 1;
  height: 64vh;
}
</style>